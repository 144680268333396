<template>
  <div>
    <a-result status="404" title="404" :sub-title="Sorry">
      <template #extra>
        <router-link to="/mendian">
          <a-button type="primary"> {{ $t("error.return") }} </a-button>
        </router-link>
      </template>
    </a-result>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Sorry: this.$t("error.Sorry"),
    };
  },
};
</script>

<style>
</style>